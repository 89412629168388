import { combineReducers, ReducersMapObject } from 'redux'
import { persistReducer } from 'redux-persist'

import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'

import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'

import JourneyReducer, { journeyPersistConfig } from './Journey/Reducer'
import { SLICE_NAME as JourneySliceName } from './Journey/Selectors'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'

import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'
import { T_THEME_REDUCER } from './Theme/TYPES'
import { TAuthReducer } from './Auth/TYPES'
import { TServiceTrackerReducer } from './ServiceTracker/TYPES'

// TODO-PRIORITY: define types for Journey and Error
export type TReducers = {
  [ThemeSliceName]: T_THEME_REDUCER
  [AuthSliceName]: TAuthReducer
  [JourneySliceName]: any
  [ErrorSliceName]: any
  [ServiceTrackerSliceName]: TServiceTrackerReducer
}

const reducers: ReducersMapObject<TReducers> = {
  [ThemeSliceName]: ThemeReducer,
  [AuthSliceName]: AuthReducer,
  [JourneySliceName]: persistReducer(journeyPersistConfig, JourneyReducer),
  [ErrorSliceName]: ErrorReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer
}

export const persistedReducers: (keyof TReducers)[] = [ThemeSliceName]

export default combineReducers<ReducersMapObject<TReducers>>(reducers)
