import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { logoutTraceActions } from './Actions'
import { INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDeviceDetails: (state, action) => {
      const { osType, deviceName } = action.payload
      state.deviceName = deviceName
      state.osType = osType
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(logoutTraceActions.success, (state, { payload }) => {
      state.logoutResponse = payload.data
    })
  }
}

const slice = createSlice(sliceOptions)

export const { setDeviceDetails } = slice.actions

export default slice.reducer
