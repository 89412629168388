import React, { Component, ErrorInfo } from 'react'
import { DsBox } from '@am92/react-design-system'

import MaintenancePage from './Pages/Maintenance/Maintenance.Page'

import { asHttp } from './Configurations/WebHttp'
import SomethingWentWrongError from './Pages/SomethingWentWrongError/SomethingWentWrongError.Page'


interface IGlobalErrorBoundaryProps {
  children: React.ReactElement
}

interface IGlobalErrorBoundaryState {
  errorCode: TErrorCode | undefined
}

type TErrorCode = 'ERR08' | '500'

const ERR_CODE_COMPONENT_MAP: Record<TErrorCode, React.ComponentType> = {
  ERR08: MaintenancePage,
  500: () => {
    return (
      <DsBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: {
            md: 'center',
            sx: 'start'
          },
          height: '100vh'
        }}
      >
        <DsBox>
          <SomethingWentWrongError />
        </DsBox>
      </DsBox>
    )
  }
}

class GlobalErrorComponent extends Component<
  IGlobalErrorBoundaryProps,
  IGlobalErrorBoundaryState
> {
  state: IGlobalErrorBoundaryState = {
    errorCode: undefined
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { errorCode: '500' }
  }

  componentDidMount() {
    asHttp.interceptors.response.use(null, config => {
      const { response = {} } = config || {}
      const { data = {} } = response
      const { error = {} } = data
      const { code = '' } = error
      if (code === 'ERR08') {
        this.setState({ errorCode: code })
      }
      return Promise.reject(config)
    })
    window.addEventListener('error', event => {
      this.setState({ errorCode: '500' })
      console.error('Caught by window.onerror:', event.message, event.error)
    })

    // Catch unhandled promise rejections
    window.addEventListener('unhandledrejection', event => {
      this.setState({ errorCode: '500' })
      console.error('Unhandled Promise Rejection:', event.reason)
    })
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error or send it to an error reporting service
    console.error('Error caught by GlobalErrorComponent:', error, errorInfo)
  }

  render() {
    const { errorCode } = this.state

    if (errorCode) {
      const ComponentToRender = ERR_CODE_COMPONENT_MAP?.[errorCode]
      return <ComponentToRender />
    }

    return this.props.children
  }
}

export default GlobalErrorComponent