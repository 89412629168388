import { To } from 'react-router'

import {
  AXIS_DIRECT_URL,
  OLD_USERNAME_FLOW,
  OPEN_ACCOUNT
} from './APP_CONSTANTS'
import APP_ROUTES from './APP_ROUTES'

type ErrorCodes =
  | 100
  | 1001
  | 1002
  | 1003
  | 1004
  | 1005
  | 1006
  | 1007
  | 1008
  | 1009
  | 1010
  | 1011
  | 1012
  | 1013
  | 1014
  | 1015
  | 1016
  | 1017
  | 1018
  | 1019
  | 1020
  | 1021
  | 1022
  | 1023
  | 1024
  | 1025
  | 1026
  | 1027
  | 1028
  | 1029
  | 1030
  | 1031
  | 1032
  | 1033
  | 1034
  | 1035
  | 1036
  | 1037
  | 1038
  | 1039
  | 1040
  | 1041
  | 1042
  | 1043
  | 1044
  | 1045
  | 1046
  | 1047
  | 1048
  | 1049
  | 1050
  | 1051
  | 1052
  | 1053
  | 1054
  | 1055
  | 1056
  | 1057
  | 1058
  | 1059
  | 1060
  | 1061
  | 1062
  | 1063
  | 1064
  | 1065
  | 1066
  | 1067
  | 1068
  | 1069
  | 1070
  | 1071
  | 1072
  | 1073
  | 1074
  | 1075
  | 1076
  | 1077
  | 1078
  | 1079
  | 1080
  | 1081
  | 1082
  | 1083
  | 1084
  | 1085
  | 1086
  | 1087
  | 1088
  | 1089
  | 1090
  | 1091
  | 1092
  | 1093
  | 1094
  | 1095
  | 1096
  | 9999
  | 9998
  | 2000
  | 2001
  | 2002
  | 2003
  | 10681
  | 10789
  | 10191

export interface Error {
  type?:
    | 'DIALOG'
    | 'FIELD_ERROR'
    | 'NOTISTACK'
    | 'NOTISTACK_NAVIGATION'
    | 'NAVIGATION'
  errorCode: number
  fieldErrors?: any
  notification?: {
    message: string
  }
  navigation?: {
    pathname: number | To
  }

  dialog?: {
    title: any
    descriptions: any
    reachOut?: {
      contactNumbers: string[]
      emails: string[]
    }
    primaryButtonLabel: string
    primaryButtonIcon?: string
    secondaryButtonLabel?: string
    secondaryButtonIcon?: string
    tertiaryButtonLabel?: string
    primaryButtonClickActions: {
      type:
        | 'EXTERNAL_LINK'
        | 'LINK'
        | 'CLOSE'
        | 'RESET_FORM'
        | 'RESET_REDUX_VALUES'
      data?: { url?: string; pathname?: number | To; redux_fields?: string[] }
    }[]
    secondaryButtonClickActions?: {
      type: string
      data?: { url?: string; pathname?: number | To; redux_fields?: string[] }
    }[]
  }
  bottomStagger?: {
    buttonGroupSeperator?: 'LOGIN WITH'
    title: any
    descriptions: any
    reachOut?: {
      contactNumbers: string[]
      emails: string[]
    }
    primaryButtonLabel: string
    secondaryButtonLabel?: string
    primaryButtonClickActions: {
      type:
        | 'EXTERNAL_LINK'
        | 'LINK'
        | 'CLOSE'
        | 'RESET_FORM'
        | 'RESET_REDUX_VALUES'
      data?: { url?: string; pathname?: number | To; redux_fields?: string[] }
    }[]
    secondaryButtonClickActions?: {
      type: string
      data?: { url?: string; pathname?: number | To; redux_fields?: string[] }
    }[]
  }
}

const ERROR_MAP: { [key: number]: Error } = {
  1001: {
    type: 'NOTISTACK',
    errorCode: 1001,
    notification: {
      message: 'Something went wrong! Please contact customer support.'
    }
  },

  1004: {
    type: 'FIELD_ERROR',
    errorCode: 1004,
    fieldErrors: {
      newPassword: 'Password can not be same as username'
    }
  },

  1005: {
    type: 'FIELD_ERROR',
    errorCode: 1005,
    fieldErrors: {
      password: (errorObject: any) => {
        const { data = { attemptsLeft: null } } = errorObject
        const { attemptsLeft } = data
        return `Incorrect password: You have only ${attemptsLeft} ${
          attemptsLeft > 1 ? 'attempts' : 'attempt'
        } left.`
      }
    }
  },

  1006: {
    type: 'NOTISTACK',
    errorCode: 1006,
    notification: {
      message: 'DeviceId not exists'
    }
  },

  1007: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1007,
    notification: {
      message: 'Max Incorrect Login Attempt Reached'
    },
    navigation: {
      pathname: APP_ROUTES.UNLOCK_ACCOUNT_ACTION_SELECTION.pathname
    }
  },

  1012: {
    type: 'NOTISTACK',
    errorCode: 1012,
    notification: {
      message: 'Failed To Generate OTP'
    }
  },

  1013: {
    type: 'NOTISTACK',
    errorCode: 1013,
    notification: {
      message: 'Generate Otp Response Failed With Errorcode'
    }
  },

  1014: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1014,
    notification: {
      message: 'Incorrect Credentials!!!'
    },
    navigation: {
      pathname: APP_ROUTES.LOGIN_CHALLENGE_PASSWORD.pathname
    }
  },

  1015: {
    type: 'FIELD_ERROR',
    errorCode: 1015,
    fieldErrors: {
      OTPCode: (errorObject: any) => {
        const { data } = errorObject
        const { attemptsLeft } = data
        return `Incorrect OTP: You have only ${attemptsLeft} ${
          attemptsLeft > 1 ? 'attempts' : 'attempt'
        } left.`
      }
    }
  },

  1017: {
    type: 'FIELD_ERROR',
    errorCode: 1017,
    fieldErrors: {
      confirmNewPassword: "Both of the passwords don't match"
    }
  },

  1018: {
    type: 'FIELD_ERROR',
    errorCode: 1018,
    fieldErrors: {
      newPassword: 'Password is same as previous used passwords'
    }
  },

  1027: {
    type: 'NOTISTACK',
    errorCode: 1027,
    notification: {
      message: 'Sorry, an account with this PAN card does not exist'
    }
  },

  1030: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1030,
    notification: {
      message: 'User is locked'
    },
    navigation: {
      pathname: APP_ROUTES.UNLOCK_ACCOUNT_ACTION_SELECTION.pathname
    }
  },

  1032: {
    type: 'DIALOG',
    errorCode: 1032,
    dialog: {
      title: 'Incomplete Nomination',
      descriptions: [
        'You have not added a nominee to your account. For your safety and security, please add a nominee to your account.'
      ],
      primaryButtonLabel: 'Add nominee',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1034: {
    type: 'DIALOG',
    errorCode: 1034,
    dialog: {
      title: 'Account closed',
      descriptions: [
        'Your account has been closed. To trade with us please open a new account'
      ],
      primaryButtonLabel: 'Open account',
      secondaryButtonLabel: 'Cancel',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: OPEN_ACCOUNT }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1035: {
    type: 'DIALOG',
    errorCode: 1035,
    dialog: {
      title: 'Where have you been?',
      descriptions: [
        'Your account has been dormant for 12 months. Please update and verify your details before you start trading again.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1036: {
    type: 'DIALOG',
    errorCode: 1036,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Support',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Skip',
      tertiaryButtonLabel: 'Request A Call',
      primaryButtonClickActions: [{ type: 'CLOSE' }],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1037: {
    type: 'DIALOG',
    errorCode: 1037,
    dialog: {
      title: 'Bank Deactivated',
      descriptions: [
        'Your bank has been deactivated due to incomplete details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Details',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1038: {
    type: 'DIALOG',
    errorCode: 1038,
    dialog: {
      title: 'Incomplete UCC',
      descriptions: [
        'You have not added your UCC (Unique Client Code) details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1039: {
    type: 'DIALOG',
    errorCode: 1039,
    dialog: {
      title: 'Incomplete KYC',
      descriptions: [
        'You need to register your KYC details to continue your trading journey with us.'
      ],
      primaryButtonLabel: 'Register KYC',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1040: {
    type: 'DIALOG',
    errorCode: 1040,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Support',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Skip',
      tertiaryButtonLabel: 'Request A Call',
      primaryButtonClickActions: [{ type: 'CLOSE' }],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1041: {
    type: 'DIALOG',
    errorCode: 1041,
    dialog: {
      title: 'Incomplete UCC',
      descriptions: [
        'You have not added your UCC (Unique Client Code) details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1042: {
    type: 'DIALOG',
    errorCode: 1042,
    dialog: {
      title: 'Incomplete UCC',
      descriptions: [
        'You have not added your UCC (Unique Client Code) details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1043: {
    type: 'DIALOG',
    errorCode: 1043,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Support',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Skip',
      tertiaryButtonLabel: 'Request A Call',
      primaryButtonClickActions: [{ type: 'CLOSE' }],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1044: {
    type: 'DIALOG',
    errorCode: 1044,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Support',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Skip',
      tertiaryButtonLabel: 'Request A Call',
      primaryButtonClickActions: [{ type: 'CLOSE' }],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1045: {
    type: 'DIALOG',
    errorCode: 1045,
    dialog: {
      title: 'Where have you been?',
      descriptions: [
        'Your account has been dormant for 24 months. Please update and verify your details before you start trading again.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1052: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1052,
    notification: {
      message: 'Empty Password'
    },
    navigation: {
      pathname: APP_ROUTES.FORGOT_PASSWORD_IDENTIFIER_PAN_EMAIL_MOBILE.pathname
    }
  },

  1055: {
    type: 'NOTISTACK',
    errorCode: 1055,
    notification: {
      message: 'Resend limit exhausted. Try again after 5 minutes'
    }
  },

  1056: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1056,
    notification: {
      message: 'OTP verification failed! Please try again'
    },
    navigation: {
      pathname: -1
    }
  },

  1057: {
    // OTP expired
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1057,
    notification: {
      message: 'OTP expired! Please try again'
    },
    navigation: {
      pathname: -1
    }
  },

  1058: {
    // Access code ref id expired
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1058,
    notification: {
      message: 'Timeout! Please try again'
    },
    navigation: {
      pathname: -1
    }
  },

  1061: {
    type: 'NOTISTACK',
    errorCode: 1061,
    notification: {
      message: 'Email not linked, please contact customer support'
    }
  },

  1068: {
    type: 'DIALOG',
    errorCode: 1068,
    dialog: {
      title: 'Email Linked to Multiple Accounts',
      descriptions: [
        'Please login with the /&mobile number/ linked to your trading account.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Login with Mobile Number',
      secondaryButtonLabel: 'Login with Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_MOBILE_PAN.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ]
    },

    bottomStagger: {
      buttonGroupSeperator: 'LOGIN WITH',
      title: 'Email Linked to Multiple Accounts',
      descriptions: [
        'Please login with the /&mobile number/ linked to your trading account.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Mobile number',
      secondaryButtonLabel: 'Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_MOBILE_PAN.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  1069: {
    type: 'DIALOG',
    errorCode: 1069,
    dialog: {
      title: 'Mobile Linked to Multiple Accounts',
      descriptions: [
        'Please login with the /&email ID/ linked to your trading account.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Login with Email ID',
      secondaryButtonLabel: 'Login with Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_MOBILE_PAN.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ]
    },
    bottomStagger: {
      buttonGroupSeperator: 'LOGIN WITH',
      title: 'Mobile Linked to Multiple Accounts',
      descriptions: [
        'Please login with the /&email ID/ linked to your trading account.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Email ID',
      secondaryButtonLabel: 'Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_MOBILE_PAN.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  1070: {
    type: 'DIALOG',
    errorCode: 1070,
    dialog: {
      title: 'Multiple Accounts Linked to Email and Mobile Number',
      descriptions: [
        'Please login with the /&username/ linked to your trading account.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Login with Username',
      secondaryButtonLabel: 'Close',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        { type: 'CLOSE' }
      ]
    },

    bottomStagger: {
      buttonGroupSeperator: 'LOGIN WITH',
      title: 'Multiple Accounts Linked to Email and Mobile Number',
      descriptions: [
        'Please login with the /&username/ linked to your trading account.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Username',
      secondaryButtonLabel: 'Close',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  1071: {
    type: 'DIALOG',
    errorCode: 1071,
    dialog: {
      title: 'Multiple Accounts Linked to Email and Mobile Number',
      descriptions: [
        'Please create /&username/ to continue with the login process.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Create Username',
      secondaryButtonLabel: 'Close',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'EXTERNAL_LINK',
          data: { url: OLD_USERNAME_FLOW }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        { type: 'CLOSE' }
      ]
    },
    bottomStagger: {
      title: 'Multiple Accounts Linked to Email and Mobile Number',
      descriptions: [
        'Please /&create username/ to continue with the login process.',
        'For help, please contact our support team.'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Create Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: {
            pathname:
              APP_ROUTES.CREATE_USERNAME_IDENTIFIER_PAN_EMAIL_MOBILE.pathname
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  1072: {
    type: 'DIALOG',
    errorCode: 1072,
    dialog: {
      title: 'Multiple Accounts Linked to Email and Mobile Number',
      descriptions: [
        'Few of your accounts have a username and other accounts have no username. Please enter or create username to continue with the login process.',
        'For help, please contact our support team'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Enter Username',
      secondaryButtonLabel: 'Create Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'EXTERNAL_LINK',
          data: { url: OLD_USERNAME_FLOW }
        },
        { type: 'CLOSE' }
      ]
    },
    bottomStagger: {
      title: 'Multiple Accounts Linked to Email and Mobile Number',
      descriptions: [
        'Few of your accounts have a username and other accounts have no username. Please /&enter/ or /&create username/ to continue with the login process.',
        'For help, please contact our support team'
      ],
      reachOut: {
        contactNumbers: ['022-40508080', '022-61480808'],
        emails: ['helpdesk@axisdirect.in']
      },
      primaryButtonLabel: 'Enter Username',
      secondaryButtonLabel: 'Create Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: { pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'LINK',
          data: {
            pathname:
              APP_ROUTES.CREATE_USERNAME_IDENTIFIER_PAN_EMAIL_MOBILE.pathname
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  1073: {
    type: 'NOTISTACK',
    errorCode: 1073,
    notification: {
      message: 'This username already exist! Try another username.'
    }
  },

  1076: {
    type: 'NOTISTACK',
    errorCode: 1076,
    notification: {
      message: 'Please enter valid credentials'
    }
  },

  1080: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1080,
    notification: {
      message: 'Session timeout! Redirecting to Login Page'
    },
    navigation: {
      pathname: APP_ROUTES.LOGIN_IDENTIFIER_MOBILE_PAN.pathname
    }
  },

  1081: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1081,
    notification: {
      message: 'Session timeout! Redirecting to Login Page'
    },
    navigation: {
      pathname: APP_ROUTES.LOGIN_IDENTIFIER_MOBILE_PAN.pathname
    }
  },

  1093: {
    type: 'NOTISTACK_NAVIGATION',
    errorCode: 1093,
    notification: {
      message: 'Too many attempts ! Account is locked'
    },
    navigation: {
      pathname: APP_ROUTES.UNLOCK_ACCOUNT_ACTION_SELECTION.pathname
    }
  },

  1094: {
    type: 'FIELD_ERROR',
    errorCode: 1094,
    fieldErrors: {
      accessCode: (errorObject: any) => {
        const { data } = errorObject
        const { attemptsLeft } = data
        return `Incorrect code: You have only ${attemptsLeft} ${
          attemptsLeft > 1 ? 'attempts' : 'attempt'
        } left.`
      }
    }
  },

  1095: {
    type: 'NOTISTACK',
    errorCode: 1095,
    notification: {
      message: 'Resend limit reached.Try again after 5 minutes'
    }
  },

  //  this is defined in FE for hasUsername flag
  2000: {
    type: 'DIALOG',
    errorCode: 2000,
    dialog: {
      title: 'Username not found!',
      descriptions: [
        'We could not find account or username for these details shared.',
        'Please double check the details entered or visit website to check account status or create username.'
      ],
      primaryButtonLabel: 'Check Username',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Edit Details',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'EXTERNAL_LINK',
          data: { url: OLD_USERNAME_FLOW }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              // 'mobileOrPan',
              // 'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  // following error codes needs to be changed as per backend definitions

  // 2001 - Mobile number not registered
  // 2002 - PAN not registered
  // 2003 - Multiple accounts found
  // 2004 - Account not activated, redirect to account activation journey

  2001: {
    type: 'DIALOG',
    errorCode: 2001,
    dialog: {
      title: 'Mobile number not registered',
      descriptions: ['Please check your entry or register a new account.'],
      primaryButtonLabel: 'Open a new account',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Edit Details',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'EXTERNAL_LINK',
          data: { url: OPEN_ACCOUNT }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              // 'mobileOrPan',
              // 'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  2002: {
    type: 'DIALOG',
    errorCode: 2002,
    dialog: {
      title: 'PAN not registered',
      descriptions: ['Please check your entry or register a new account.'],
      primaryButtonLabel: 'Open a new account',
      primaryButtonIcon: 'ri-external-link-fill',
      secondaryButtonLabel: 'Edit Details',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              'mobileOrPan',
              'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        {
          type: 'EXTERNAL_LINK',
          data: { url: OPEN_ACCOUNT }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: [
              // 'mobileOrPan',
              // 'username',
              'identifierType',
              'isTermsAndConditionsAccepted'
            ]
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  10681: {
    type: 'DIALOG',
    errorCode: 10681,
    dialog: {
      title: 'Multiple accounts found',
      descriptions: (metaData: { mobileNumber: string }) => {
        const { mobileNumber } = metaData
        return [
          `The number "+91-${mobileNumber}" is Registered with multiple accounts. Please log in using PAN card or Username.`
        ]
      },
      primaryButtonLabel: 'Login with PAN / Username',
      primaryButtonClickActions: [
        {
          type: 'RESET_REDUX_VALUES',
          data: {
            redux_fields: ['mobileOrPan']
          }
        },
        { type: 'CLOSE' }
      ]
    }
  },

  10789: {
    type: 'NAVIGATION',
    errorCode: 10789,
    navigation: {
      pathname: APP_ROUTES.ACCOUNT_ACTIVATION_CHALLENGE_OTP.pathname
    }
  }
  // 10191
}

export const PAGE_HANDLED_ERROR_CODES = [
  1032, 1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045
]

export const REDUX_RESET_ERROR_CODES = [1003, 1068, 1069, 1070, 1071, 1072]

const HOC_HANDLED_ERROR_CODES: number[] = []

for (const code of Object.keys(ERROR_MAP)) {
  if (!PAGE_HANDLED_ERROR_CODES.includes(Number(code))) {
    HOC_HANDLED_ERROR_CODES.push(Number(code))
  }
}

export { HOC_HANDLED_ERROR_CODES }

export const getErrorObj = (errorCode: ErrorCodes) => {
  return ERROR_MAP[errorCode]
}
