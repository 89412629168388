// TODO-PRIORITY: define valid types
export type TLogoutResponse = {}

export type TAuthReducer = {
  isLoggedIn: boolean
  accessToken: string
  refreshToken: string
  deviceName: string | null
  osType: string | null
  logoutResponse: any
}

export const INITIAL_STATE: TAuthReducer = {
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
  deviceName: null,
  osType: null,
  logoutResponse: {}
}
