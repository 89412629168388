export const AXIS_DIRECT_URL =
  'https://simplehai.axisdirect.in/app/index.php/user/auth/LoginSupport'

export const OLD_USERNAME_FLOW =
  'https://simplehai.axisdirect.in/app/index.php/user/auth/activateUser'

export const TERMS_CONDITIONS =
  'https://simplehai.axisdirect.in/terms-and-conditions-home'

export const OPEN_ACCOUNT =
  'https://digitalaccount.axisdirect.in/register?utm_source=Investor_App&utm_campaign=Investor_App_Web'

// Constants for License and Registration Information
export interface ILicenseRegistration {
  header: string
  subheader: string
}

export const LICENSE_REGISTRATION: ILicenseRegistration[] = [
  {
    header: 'NSE, BSE, MSEI, MCX & NCDEX - SEBI Single Reg. No.',
    subheader: 'INZ000161633'
  },
  {
    header: 'NSDL & CDSL - SEBI Depository Participant Reg. No.',
    subheader: 'IN-DP-403-2019'
  },
  {
    header: 'SEBI-Research Analyst Reg. No.',
    subheader: 'INH 000000297'
  }
]

export const LICENSE_REGISTRATION_STRING = LICENSE_REGISTRATION.map(
  item => `${item.header} ${item.subheader}`
).join(' | ')

export const PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=com.axisdirect.invest&pcampaignid=web_share'

export const APPLESTORE_LINK =
  'https://apps.apple.com/in/app/axis-direct-investor/id1642276910'
