import { configureStore } from '@reduxjs/toolkit'
import reducers, { persistedReducers, TReducers } from '~/src/Redux/Reducers'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import localforage from 'localforage'

const persistConfig = {
  key: process.env.APP_TITLE || 'Axis Securities Limited(ASL)',
  version: 1,
  storage: localforage,
  whitelist: persistedReducers
}

const AppStore = configureStore({
  reducer: persistReducer<TReducers>(persistConfig, reducers),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
  devTools: process.env.NODE_ENV !== 'production'
})

export type RootState = ReturnType<typeof AppStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof AppStore.dispatch

const PersistedAppStore = persistStore(AppStore)

export default AppStore

type TAppStore = ReturnType<typeof AppStore.getState>

type TAppDispatch = typeof AppStore.dispatch

export { TAppStore, TAppDispatch, PersistedAppStore }
