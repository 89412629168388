import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { DsDialogDefaultProps } from './DsDialog.Types';
import { DsDialogTitle } from '../DsDialogTitle';
import { Dialog } from '@mui/material';
import { DsIconButton } from '../DsIconButton';
import { DsRemixIcon } from '../DsRemixIcon';
import { DsTypography } from '../DsTypography';
import { DsDialogContent } from '../DsDialogContent';
import { DsDialogActions } from '../DsDialogActions';
import { DsButton } from '../DsButton';
export class DsDialog extends React.Component {
    static defaultProps = DsDialogDefaultProps;
    handleDialogClose = (event, reason) => {
        const { onClose } = this.props;
        if (typeof onClose === 'function') {
            onClose(event, reason);
        }
    };
    handleCloseClick = (event) => {
        const { onClose } = this.props;
        if (typeof onClose === 'function') {
            onClose(event, 'backdropClick');
        }
    };
    render() {
        const { title, description, showClose, primaryButtonText, primaryButtonProps, secondaryButtonText, secondaryButtonProps, PaperProps, TitleProps, DescriptionProps, CloseIconButtonProps, CloseIconProps, ContentProps, ActionsProps, children, ...DialogProps } = this.props;
        const actionsAvailable = !!(primaryButtonText || secondaryButtonText);
        const accessibilityProps = {};
        const isFlushed = primaryButtonText &&
            !secondaryButtonText &&
            primaryButtonProps &&
            primaryButtonProps?.variant === 'flushed';
        if (title) {
            accessibilityProps['aria-labelledby'] = title;
        }
        if (description) {
            accessibilityProps['aria-describedby'] = description;
        }
        return (_jsxs(Dialog, { keepMounted: true, ...accessibilityProps, ...DialogProps, PaperProps: {
                ...PaperProps,
                sx: {
                    pb: isFlushed
                        ? undefined
                        : {
                            xs: 'var(--ds-spacing-bitterCold)',
                            md: 'var(--ds-spacing-warm)'
                        },
                    pt: {
                        xs: 'var(--ds-spacing-mild)',
                        md: 'var(--ds-spacing-warm)'
                    },
                    ...PaperProps?.sx
                }
            }, children: [title && (_jsx(DsDialogTitle, { ...TitleProps, sx: {
                        width: showClose ? 'calc(100% - 44px)' : '100%',
                        px: {
                            xs: 'var(--ds-spacing-bitterCold)',
                            md: 'var(--ds-spacing-warm)'
                        },
                        ...TitleProps?.sx
                    }, children: title })), description && (_jsx(DsTypography, { variant: "subheadingSemiboldDefault", color: "text.secondary", ...DescriptionProps, sx: {
                        px: {
                            xs: 'var(--ds-spacing-bitterCold)',
                            md: 'var(--ds-spacing-warm)'
                        },
                        ...DescriptionProps?.sx
                    }, children: description })), showClose && (_jsx(DsIconButton, { onClick: this.handleCloseClick, ...CloseIconButtonProps, sx: {
                        position: 'absolute',
                        padding: 'var(--ds-spacing-quickFreeze)',
                        borderRadius: 'var(--ds-radius-mild)',
                        top: {
                            xs: 'var(--ds-spacing-mild)',
                            md: 'var(--ds-spacing-warm)'
                        },
                        right: {
                            xs: 'var(--ds-spacing-bitterCold)',
                            md: 'var(--ds-spacing-warm)'
                        },
                        ...CloseIconButtonProps?.sx
                    }, children: _jsx(DsRemixIcon, { className: "ri-close-line", ...CloseIconProps }) })), children && (_jsx(DsDialogContent, { ...ContentProps, sx: {
                        px: {
                            xs: 'var(--ds-spacing-bitterCold)',
                            md: 'var(--ds-spacing-warm)'
                        },
                        marginTop: 'var(--ds-spacing-mild)',
                        ...ContentProps?.sx
                    }, children: children })), actionsAvailable && (_jsxs(DsDialogActions, { ...ActionsProps, sx: {
                        px: isFlushed
                            ? undefined
                            : {
                                xs: 'var(--ds-spacing-bitterCold)',
                                md: 'var(--ds-spacing-warm)'
                            },
                        ...ActionsProps?.sx
                    }, children: [(secondaryButtonText || secondaryButtonProps?.children) && (_jsx(DsButton, { color: "secondary", size: "large", fullWidth: true, ...secondaryButtonProps, children: secondaryButtonText || secondaryButtonProps?.children })), (primaryButtonText || primaryButtonProps?.children) && (_jsx(DsButton, { size: "large", fullWidth: true, ...primaryButtonProps, children: primaryButtonText || primaryButtonProps?.children }))] }))] }));
    }
}
